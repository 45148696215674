import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Ripping the bandaid off`}</h1>
    <p>{`Hey guys -`}</p>
    <p>{`Let’s rip the bandaid off - This is going to be the last month of the All Access Pass.`}</p>
    <p>{`I was pretty torn on this decision.`}</p>
    <p>{`On one hand - the community has been a blast for me (especially our live zoom calls/AMAs), and I’ve loved getting to know many of you.`}</p>
    <p>{`On the other hand - I have a bunch of responsibilities (job, dad, fund, podcast etc..), and writing the AAP email everyday has been tough!`}</p>
    <p>{`Originally I planned to do this until xmas (4 months) but we’re going to end it at Thanksgiving instead (3 months).`}</p>
    <p>{`So….why stop at 3 months?`}</p>
    <p>{`Two reasons:`}</p>
    <ol>
      <li parentName="ol">{`It is a lotta work`}</li>
      <li parentName="ol">{`My long term dream is to teach as many people as I can (my target goal is 70M people) and right now 90% of the content i make for AAP is behind a paywall...so that’s not helping me get there.`}</li>
    </ol>
    <p>{`I’ll dive into more details on each of those but first some logistical info that I don’t want to get buried at the end of the email:`}</p>
    <p>{`The logistical stuff you need to know:`}</p>
    <ul>
      <li parentName="ul">{`Your memberships will automatically not-renew, no action required on your part`}</li>
      <li parentName="ul">{`The last email will be Nov 25th (day before Thanksgiving)`}</li>
    </ul>
    <p>{`OK - so why end this after 3 months?`}</p>
    <p><strong parentName="p">{`It’s a lotta work`}</strong></p>
    <p>{`The All Access Pass was an experiment of “building in public” and pushing my content creating abilities to the limits (can I put out high quality stuff, every mother effin day?)`}</p>
    <p>{`In the last two months, we created over 50 pieces of content. That’s normally what I’d put out in 2 years..squeeeeezed into three months.`}</p>
    <p>{`I had never tried this content workload before.`}</p>
    <p>{`It was like training for an ironman competition. Except way less cool because it’s email instead of exercise.`}</p>
    <p>{`Anyhow - the pace was pretty relentless. Every night after my day job was done, the baby was asleep, and I’d take my dog for a walk...then I’d sit down and hammer out a blog post and try to deliver max value to you guys.`}</p>
    <p>{`I enjoyed doing it for a season, but it’s not something I’d want to continue doing year round. Mad respect to people like Casey Neistat who did daily vlogs for like 534 days straight before burning out.`}</p>
    <p>{`I felt like a bodybuilder trying to eat 8,000 calories a day. Sure, it sounds fun to eat as much as you want...but at some point you’re just forcing down food. I love creating content, but this was a lot.`}</p>
    <p>{`I also couldn’t just pump out crap. After all, you guys are paying big money for this stuff! I don’t take that lightly. The average person is paying $199 per month, so I really care about delivering golden nuggets of insight.`}</p>
    <p>{`Even though I’m pretty hard on myself - the stats say we did a good job: 93% paid member retention, and 77% average open rate on the emails.`}</p>
    <p>{`On top of writing the email, we actually had to launch new things in order to have stuff to write about.`}</p>
    <p><strong parentName="p">{`Fund`}</strong><br parentName="p"></br>{`
`}{`We launched a venture fund and raised $2.6M from investors we had never met before, in 21 days.`}</p>
    <p><strong parentName="p">{`Ecom`}</strong></p>
    <p>{`We got a new ecommerce product manufactured (kids play couch) and grew my wife’s ecom store to $100k+ in monthly revenue.`}</p>
    <p><strong parentName="p">{`Course`}</strong></p>
    <p>{`We planned to launch an online course but pause on this project. Well, here’s the spoiler why. I just invested in Gagan Biyani’s `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/qduzl6v5vou7hzol2vtl/dpheh0hqz6qm28sm/aHR0cHM6Ly93d3cubm90aW9uLnNvL1dlcy1hbmQtR2FnYW4tcy1uZXctc3RhcnR1cC1iZjhhZTc4OWZkZWQ0NzUzYjBmNTRhODVjZTUzMTVjMA=="
      }}>{`new course platform`}</a>{`. The deal was really hard to get into because Gagan is a baller (he cofounded Udemy, which is a $3B education platform) and many investors wanted to invest. So in order to get in I made a trade. I promised I would launch my course on his platform (when it’s ready), if he let me invest in the deal.`}</p>
    <p>{`So that’s the inside scoop on why I decided not to do a course during the AAP. Sorry, I couldn’t say anything until they did the `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/qduzl6v5vou7hzol2vtl/e0hph7hk9vk2d4a8/aHR0cHM6Ly90d2l0dGVyLmNvbS9nYWdhbmJpeWFuaS9zdGF0dXMvMTMyNjk0ODU0OTc3OTc0MjczMg=="
      }}>{`funding announcement`}</a>{` (which they just did 2 days ago).`}</p>
    <p><strong parentName="p">{`Anyways`}</strong>{` - the point is, we did a lotta shit in 2.5 months. It’s been fun for me, and I hope you guys have enjoyed it. So why stop? `}</p>
    <p>{`Most people think I’m crazy.`}</p>
    <p><em parentName="p">{`You’re making almost $50k a month on a paid newsletter, and you’re shutting it down voluntarily?`}</em></p>
    <p>{`(....Damn, it does sound a little crazy when you say it like that)`}</p>
    <p>{`The work was hard, but it wasn’t the reason I decided to stop.`}</p>
    <p>{`The main reason to change was because I realized I wasn’t moving towards my dream.`}</p>
    <p>{`I set out a 10 year goal for myself this year. I want 1% of the world’s population (70 million people) to consider me one of their favorite teachers. Someone who really shifted their thinking and helped them with their life.`}</p>
    <p>{`70M is a big number, but luckily, 10 years is a long time.`}</p>
    <p>{`As they say ‘we overestimate what we can do in a year, and underestimate what we can do in a decade’.`}</p>
    <p>{`Right now I reach somewhere between 10k (email) and 50k people per month (podcast). And some fraction of them (20%?) would consider me one of their favorite teachers. So let’s say generously that I’m at 20% x 50,000 = 10,000 students.`}</p>
    <p>{`I got a long way to go to get to 70M!`}</p>
    <p>{`In order to get there, I need to put out content that’s free for the masses.`}</p>
    <p>{`I had assumed that I could just take some of the all access content, and publish it more widely..but to be honest, I never got around to it. I was so busy making content for you guys, that I just put the free list on the backburner.`}</p>
    <p>{`That’s the danger of taking money from someone. Then I feel obliged to deliver! Any hour I had to make content, I put into the all access pass, leaving no time for growing my overall audience.`}</p>
    <p>{`So I had a decision to make. Keep making great content for the ~320 ish members of the AAP...or kill the AAP and spend that time making free content for the masses.`}</p>
    <p>{`The decision was clear for me. It was time to wind down the AAP. I want to leave you with two things:`}</p>
    <p>{`First - a huge thank you for doing this experiment with me. I really hope you enjoyed it, and learned things from it. This won’t be the last time our paths cross, I’m sure.`}</p>
    <p>{`Second - forget about all the tactics and frameworks...there’s really one big takeaway I hope you have:`}</p>
    <p>{`And the takeaway is this:`}</p>
    <ol>
      <li parentName="ol">{`This guy isn’t special`}</li>
      <li parentName="ol">{`He’s just as clueless as me about a bunch of things`}</li>
      <li parentName="ol">{`If this guy made it, I can make it too`}</li>
    </ol>
    <p>{`Believing that ^ means you believe in yourself. And that’s really what separates those who `}<strong parentName="p">{`do big shit`}</strong>{` vs. those who wish they were doing big shit.`}</p>
    <p><strong parentName="p">{`ALMOST last thing…`}</strong></p>
    <p>{`Here’s what's coming down the pipe for the last couple weeks:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The Slack will stay alive for now, but in my experience these things fade away unless a handful of people decide to take charge of the community and keep it active. So if you are getting value from it, keep it going!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We’re creating a simple archive of all the posts & tools we use, so you can access them without needing to dig into your inbox`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I’m creating a summary PDF from the 2nd half of the all access pass like we did for the 1st half (`}<a parentName="p" {...{
            "href": "https://click.convertkit-mail2.com/qduzl6v5vou7hzol2vtl/7qh7h8h0vl0mx3fz/aHR0cHM6Ly9kb2NzZW5kLmNvbS92aWV3L2pqc2Z5cmJmcHBuM3Q2OGY="
          }}>{`link`}</a>{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I’m going to share more of my personal stuff (my life vision document, my annual review doc). These are super important tools for me, and I think you’ll like it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`We’re going to finish up / close the chapter on the new ecommerce product (the kids play couch) by creating the website, pre-sale marketing etc..`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Alright last thing - a word from Ben. My wingman in this whole thing, and without whom I could have never pulled this off. Ben...take it away:`}</strong></p>
    <p>{`Wow, tough act to follow Shaan here!`}</p>
    <p>{`Getting to work in public for y’all has been a ride for me. Most of you bought to see Shaan work openly, not me. But every one of you has been welcoming and receptive when i’ve put out content, so for that I’m grateful!`}</p>
    <p>{`In the past 3 months, I’ve learned a shit ton. One of the coolest things is that Shaan’s community is full of a lot of awesome people, working on really interesting things. From `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/qduzl6v5vou7hzol2vtl/owhkhqh439470xhv/aHR0cHM6Ly90d2l0dGVyLmNvbS9UcmV2TWNLZW5kcmljaw=="
      }}>{`Trevor`}</a>{` (the future kingmaker of Chief of Staff’s in tech) to `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/qduzl6v5vou7hzol2vtl/z2hghnho96o8mnip/aHR0cHM6Ly90d2l0dGVyLmNvbS9Uc3F1YXJlZFQ="
      }}>{`Tessa`}</a>{` (reinventing recruiting for collegiate athletics) to `}<a parentName="p" {...{
        "href": "https://click.convertkit-mail2.com/qduzl6v5vou7hzol2vtl/p8heh9h9k69lp6fq/aHR0cHM6Ly90d2l0dGVyLmNvbS9jb2FjaHZhbGxleQ=="
      }}>{`Mike`}</a>{` (a former NHL coach who has so many cool projects there’s not enough space to list em all haha) + countless others`}</p>
    <p>{`Not only have i learned a lot from the community, but working with Shaan has taught me a bunch of things that most of you know by now.`}</p>
    <p>{`I think the most important thing he's taught me is energy wins. Every morning, i wake up and try to bring the juice. Consistently. Because, if you’re juiceless, you’re useless (shaan stole this from jeff van gundy btw, so i’m stealing it too).`}</p>
    <p>{`As for what’s next for us, well we’re gonna keep bringing that damn juice every day. And...we’ve been keeping a list of fun projects we could do after all access pass is over...maybe it’s time to take that list out of the shoebox 😼`}</p>
    <p>{`-`}{` shaan and ben`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      